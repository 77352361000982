import App from "@/views/App.vue";

export default [
  {
    path: "/meu-perfil",
    name: "profile",
    component: App,
    redirect: { name: "profileHome" },
    children: [
      {
        path: "",
        name: "profileHome",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/Profile.vue"),
        meta: {
          displayName: "Meu perfil - Exclusive Lounge",
          requiresAuth: true,
        },
      },
      {
        path: "/passo-perfil",
        name: "profileStep",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/StepProfile.vue"),
        meta: {
          displayName: "Meu perfil - Exclusive Lounge",
          requiresAuth: true,
        },
      },
      {
        path: "/meu-perfil/projetos-atuais",
        name: "profileCurrentProjects",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/CurrentProjects.vue"
          ),
        meta: {
          displayName: "Projetos Atuais - Exclusive Lounge",
          requiresAuth: true,
        },
      },
      {
        path: "/meu-perfil/realizacoes",
        name: "profileAchievements",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/Achievements.vue"),
        meta: {
          displayName: "Realizações - Exclusive Lounge",
          requiresAuth: true,
        },
      },
      {
        path: "/meu-perfil/editar-informacoes",
        name: "profileEditInfo",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/EditProfile.vue"),
        meta: {
          displayName: "Editar Informações - Exclusive Lounge",
          requiresAuth: true,
        },
      },
      {
        path: "/perfil/:id",
        name: "profileCLevel",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/ProfileCLevel.vue"),
        meta: {
          displayName: "Perfil C-level - Exclusive Lounge",
          requiresAuth: true,
        },
      },
      {
        path: "/perfil-marketing/:id",
        name: "profileMarketing",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/ProfileMarketing.vue"
          ),
        meta: {
          displayName: "Perfil Empresa - Exclusive Lounge",
          requiresAuth: true,
        },
      },
      {
        path: "/formulario-case",
        name: "profileCase",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/SubmitCase.vue"),
        meta: {
          displayName: "Formulário case - Exclusive Lounge",
          requiresAuth: true,
        },
      },
    ],
  },
];
