
import { TOOLBAR_BUTTONS } from "../mocks/toolbar";
import { Action, State } from "vuex-class";
import Component, { mixins } from "vue-class-component";
import { MiniProfile } from "@/modules/profile/types";
import { ActionMixin } from "../mixins/ActionMixin";
import { ModalMixin } from "../mixins/ModalMixin";
import { Prop, Watch } from "vue-property-decorator";
import { TokenService } from "@/api/storage";
@Component
export default class TheToolbar extends mixins(ActionMixin, ModalMixin) {
  @Prop(Boolean) isNotification: boolean;
  @Prop(Boolean) isMessageNotification: boolean;
  @Prop(Boolean) isNewCommunity: boolean;
  toolbarItems = TOOLBAR_BUTTONS;
  modalNewAnnouncement = false;
  modalNewPost = false;
  filter = "";
  currentUser: MiniProfile = {
    id: 0,
    name: "",
    companyName: "",
    occupation: "",
    profileImage: "",
    role: "",
    hasMedal: false,
    userTheme: "",
  };
  show = false;
  @State("currentFilter") currentFilter: any;
  @State((state) => state.community.notificationNewCommunity)
  notificationNewCommunity: any;
  @Action("updateFilter") updateFilter: any;
  @Action("profile/requestMe") requestMe: any;
  @Action("profile/requestUpdateTheme") requestUpdateTheme: any;
  @Action("requestLogout") requestLogout: any;
  @Action("updateRouteAfterLogin") updateRouteAfterLogin: any;
  @Action("updateFilterInput") updateFilterInput: any;
  get canCreatePost(): boolean {
    return (
      TokenService.getRole() === "PARTICIPANT" ||
      TokenService.getRole() === "EQUIPE" ||
      TokenService.getRole() === "PARTNER"
    );
  }
  get buttonsToolbar(): any {
    return TokenService.getRole() === "EXECUTIVE"
      ? this.toolbarItems
      : this.toolbarItems.filter((item: any) => item.route !== "leadsHome");
  }
  created(): void {
    this.filter = this.currentFilter;
    this.fetchMe();
  }
  async fetchMe(): Promise<void> {
    await this.withLoading(async () => {
      await this.requestMe().then((data: MiniProfile) => {
        this.currentUser = data;
        this.show = true;
        // this.$vuetify.theme.dark = data.userTheme === "DARK";
      });
    });
  }
  imageButton(item: any): any {
    return this.$route.name === item.route ? item.imageSelected : item.image;
  }
  classButton(route: string): string {
    return this.$route.name === route ? "the-toolbar__col" : "";
  }
  redirectSearch(): void {
    if (this.$route.name !== "searchHome")
      this.$router.push({ name: "searchHome" });
  }
  updateStoreFilter(): void {
    if (!this.filter) return;
    this.updateFilter(this.filter);
    this.redirectSearch();
  }
  clearStoreFilter(): void {
    this.filter = "";
    this.updateFilter(this.filter);
    this.redirectSearch();
  }
  redirectRoute(route: string): void {
    if (route === "loginPage") {
      this.updateRouteAfterLogin({ name: "", params: {} });
      this.requestLogout();
    }
    this.$router.push({ name: route });
  }
  openModal(): void {
    this.$router.push({ name: "newPostHome" });
  }
  closeModalNewPost(): void {
    this.modalNewPost = false;
    this.unblockScroll();
  }
  closeModalNewAnnouncement(): void {
    this.modalNewAnnouncement = false;
    this.unblockScroll();
  }
  async updateTheme(item: boolean): Promise<void> {
    const payload = `?theme=${item ? "DARK" : "LIGHT"}`;
    await this.withLoading(async () => {
      await this.requestUpdateTheme(payload);
    });
  }
  redirectTimeline(): void {
    if (this.$route.name !== "imelineHome")
      this.$router.push({ name: "timelineHome" });
  }
  @Watch("filter", { immediate: true, deep: true })
  updateValueFilter(): void {
    this.updateFilterInput(this.filter);
  }
}
