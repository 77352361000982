type header_axios = {
  Authorization: string;
  "Access-Control-Expose-Headers": string;
  "Access-Control-Allow-Origin": string;
};
export const makeAuthorizationHeader = (accessToken: string): header_axios => ({
  Authorization: `Bearer ${accessToken}`,
  "Access-Control-Expose-Headers": "hash-page-controll",
  "Access-Control-Allow-Origin": "*",
});
